.v-alert {
  border: none;
  color: white;
  font-size: 16px;
  position: fixed;
  left: 50%;
  top: $v-navbar-height + $v-baseline/2;
  .maintenance-banner-show & {
    top: $v-navbar-height + $v-maintenance-banner-height + $v-baseline/2;
  }
  transform: translate(-50%, 0);
  z-index: 99999!important;

  & > p + p {
    margin-top: 8px;
  }
}

.v-inline-alert {
  display: flex;
  background-color: var(--Colour-Styles-Neutral-Light-Grey, #ffe8e9);
  border-radius: var(--Sizing-Scale-Tiny, 15px);
  font-size: $v-font-size-standard;
  font-weight: lighter;
  height: 24px;
  color: var(--colour-styles-primary-black-accessible, #D10711);
  align-items: center;
}
